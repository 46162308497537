import React from "react";
import Footer from "../gui/footer";
import { Header } from "../nav";

/*
  This layout is wrapped around the whole app via gatsby-plugin-layout
  Rather than adding the layout per page (which causes rerendering and reduces performance)
*/
const Layout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);
export default Layout;
