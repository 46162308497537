import React, { useEffect } from "react";

function Overlay({ children, isOpen, setIsOpen }) {
  function closeOnEscapeKey(event) {
    if (event.keyCode === 27 && isOpen) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      window.removeEventListener("keydown", closeOnEscapeKey);
    };
  });

  useEffect(() => {
    document
      .querySelectorAll("body, html")
      .forEach((e) =>
        e.classList[isOpen ? "add" : "remove"]("overflow-hidden")
      );
  }, [isOpen]);

  return (
    <div
      className={`
    fixed z-50 block bg-gray-50 text-white transition duration-300 ease-in-out
    ${isOpen ? `inset-0 opacity-100` : `opacity-0`}
    `}
    >
      <div
        className={`flex flex-col h-full max-h-full
      ${isOpen ? `block` : `hidden`}
      `}
      >
        <div className="fixed top-0 right-0 mt-4 mr-4">
          <button
            className={`text-white focus:outline-none select-none highlight-none
            transition duration-500 ease-in-out
            ${isOpen ? `opacity-100 block` : `opacity-0 hidden`}
            `}
            onClick={() => setIsOpen(false)}
          >
            <svg
              className="hi-outline hi-x inline-block w-12 h-12 text-blue-600"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>{" "}
          </button>
        </div>
        <div className="flex flex-grow overflow-hidden">{children}</div>
      </div>
    </div>
  );
}

export default Overlay;
