import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import MenuMobile from "./MenuMobile/MenuMobile";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  `);
  return (
    <nav className="container px-5">
      <div className="py-8 relative ">
        <div className="absolute -right-full -left-full inset-y-0 z-0 border-b-2 border-slate-100" />
        {/* Mobile */}
        <div className="block sm:hidden flex justify-between items-center relative">
          <Link
            to="/"
            className={`group flex flex-row items-center content-center`}
          >
            <svg
              className="hi-solid hi-home inline-block w-8 h-8 text-blue-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
          </Link>
          <div className={`group flex flex-row items-center content-center`}>
            <button
              className="sm:hidden"
              onClick={() => setIsMenuOpen(true)}
              aria-label="Open Menu"
            >
              <svg
                className="hi-outline hi-menu-alt-2 inline-block w-6 h-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </button>
          </div>
        </div>
        {/* Tablet and desktop */}
        <div className="hidden sm:flex justify-between items-center relative">
          <Link
            to="/"
            className={`group flex flex-row items-center content-center`}
          >
            <svg
              className="hi-solid hi-home inline-block w-8 h-8 text-blue-500 hover:text-blue-600"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
            </svg>
          </Link>
          <div className={`group flex flex-row items-center content-center`}>
            {site.data.menu.map(({ name, to }, key) =>
              to !== "/" ? (
                <Link
                  to={to}
                  key={`desktop-nav-${key}`}
                  className={`group flex flex-row items-center content-center`}
                >
                  <div
                    className={`text-black hover:text-blue-600 text-md font-semibold mr-4`}
                  >
                    {name}
                  </div>
                </Link>
              ) : null
            )}
          </div>
        </div>
        <MenuMobile
          isOpen={isMenuOpen}
          setIsOpen={setIsMenuOpen}
          links={site.data.menu}
        />
      </div>
    </nav>
  );
};

export default Header;
